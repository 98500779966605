import React, { useState } from "react";
import classNames from "classnames";
import { Fab, Tooltip, withStyles } from "@material-ui/core";
import { CircularProgress, Checkbox } from "@material-ui/core";
import ConfiguracoesIcon from "../../../../components/Icon/Configuracoes";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import InputMaskCurrency from "../../../../components/Input/InputMaskCurrency";
import ModalConfigVigencia from "../../../../components/Modal/ModalConfigVigencia/ModalConfigVigencia";
import string from "../../../../utils/string";
import moment from "moment";

const ProcedimentoList = ({
  classes,
  listaModal,
  handleChangeCheckbox,
  handleChange,
  loadMore,
  lastScroll,
  loadingModal,
  hiddenValor,
  showQuantidade,
  convenioSelected,
}) => {

  const [openModal,setOpenModal]=useState(false)
  const [procedimento,setProcedimento]=useState(null)


  const handleClick = (e) => {
    const{procedimentos}=e
    setOpenModal(true)
    const procedimento = {
     nome:procedimentos.nome,
     id:procedimentos.id

    }
    setProcedimento(procedimento)
  }

  const handleClose =()=>{
    setOpenModal(false)
  }


  const getVigencia = (procedimento) => {
    const { vigencia } = procedimento || {};
    const { dataInicioVigencia, dataFimVigencia } = vigencia || {}

    const dataInicioFormatada = dataInicioVigencia && moment(dataInicioVigencia).format("DD/MM/YYYY")
    const dataFimFormatada = dataFimVigencia && moment(dataFimVigencia).format("DD/MM/YYYY")

    return `${dataInicioFormatada || ""} - ${dataFimFormatada || ''}`;

  }

  return (
    <div className={classes.content}>
      <Scroll
        loadMore={loadMore}
        hasMore={!lastScroll}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <div className={classes.titles}>
          <div className={classNames(classes.title,classes.selecioneProcedimento)}> Selecione o procedimento </div>
          {!hiddenValor && <div className={classNames([classes.title, convenioSelected.id ? classes.titleValor : classes.titleValorSemConvenio])}>
            Valor
          </div>}
          {convenioSelected.id &&
            <div className={classNames(classes.title, classes.titleAcao)}>Vigencia</div>
          }
          {convenioSelected.id &&
            <div className={classNames(classes.title, classes.titleAcao)}>Ação</div>
          }
        </div>
        {listaModal.length > 0 &&
          listaModal.map((itemLista, index) => (
            <div className={classes.linha} key={index}>
              <div className={classes.checkboxDescricao}>
                <Checkbox
                  color={"primary"}
                  onChange={() => handleChangeCheckbox(itemLista, index)}
                  checked={itemLista.procedimentos.checked}
                />
                <Tooltip title={itemLista.procedimentos.nome} placement={"top-start"}>
                  <div className={classes.procedimento}>
                    {string.truncate(itemLista.procedimentos.nome, 20)}
                  </div>
                </Tooltip>
              </div>
              { !hiddenValor && <InputMaskCurrency
                name={"valor"}
                value={itemLista.valor === 0 ? "" : itemLista.valor}
                className={classNames(
                  classes.inputValor,
                  !itemLista.procedimentos.checked &&
                    classes.inputValorDesabilitado
                )}
                onChange={(e) => handleChange(e, itemLista, index)}
                disabled={!itemLista.procedimentos.checked}
              />
              }
              {convenioSelected.id &&
                <div className={classes.dataVigencia}>
                  {getVigencia(itemLista)}
                </div>
              }
              {convenioSelected.id &&
                <Fab
                  size={"small"}
                  className={classes.config}
                  onClick={() => handleClick(itemLista)}
                ><ConfiguracoesIcon color={"#868686"} />
                </Fab>}
            </div>
          ))}
        {loadingModal && (
          <div className={classes.contentLoading}>
            <CircularProgress />
          </div>
        )}
      </Scroll>
      {openModal &&
      <ModalConfigVigencia
      title={`${procedimento?.nome}${convenioSelected.descricao ? ` - ${convenioSelected.descricao}` : ""}`}
      open={openModal}
      convenio={convenioSelected}
      handleClose={handleClose}
      procedimentoSelected={procedimento}
      />
      }
    </div>
  );
};

const styles = {
  content: {
    height: "calc(100% - 105px)",
    overflow: "auto",
  },
  titles: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#fff",
  },
  title: {
    fontSize: "10px",
    color: "#868686",
  },
  titleValor: {
    width: "100%",
    maxWidth: "104px",
    textAlign: 'center',
    marginRight:"35px",
  },
  titleValorSemConvenio:{
    width: "100%",
    maxWidth: "104px",
    textAlign: 'center',
  },
  procedimento: {
    color: "#868686",
    minWidth:"200px",
  },
  linha: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
    height: "40px",
  },
  inputValor: {
    background: "#F2F2F2",
    padding: "4px 4px 4px 8px",
    width: "90px",
    height: "30px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
  },
  checkboxDescricao: {
    display: "flex",
    alignItems: "center",
    "&>span": {
      color: "#868686",
    },
  },
  contentLoading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  scrollContainerModelos: {
    height: "100%",
  },
  inputValorDesabilitado: {
    color: "#E5E5E5",
  },
  config: {
    cursor: 'pointer',
    height: '14px',
    padding: '0',
    background: '#ffffff',
    border: 'none',
    boxShadow: 'none'
  },
  selecioneProcedimento:{
    width:"220px",
  },
  titleAcao:{
    textAlign: 'end',
    width: '100px',
    marginRight: '10px'
  },
  dataVigencia:{
    width: '165px',
    height: '40px',
    borderRadius: '8px',
    background: '#f2f2f2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textWrap: 'nowrap',
    fontSize: '12px',
    color: '#505050',
    fontWeight: '500'
  }

};

export default withStyles(styles)(ProcedimentoList);
