import React from "react";
import { inject, observer } from "mobx-react";

import { CircularProgress, Switch, withStyles } from "@material-ui/core";
import { findAllConvenio, saveConvenio, findByIdConvenio, findAllConvenioSistemaByConvenio, convenioNomeJaExistente } from '../../../services/ConvenioService';
import { findAllProduto } from '../../../services/ProdutoService';
import { buildUrlDownload, db } from "../../../config/config";
import Notification from '../../../components/Notification';
import { findAllProcedimentos } from '../../../services/ProcedimentoService';
import string from "../../../utils/string";
import { findAllAgendamentoTipoSearch } from "../../../services/AgendamentoTipoService";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import { tabs } from "../../../stores/ModalConfiguracoes.store";
import { findAllConvenioAuditByConvenioId, findAllConvenioAuditByUnidade } from "../../../services/AuditoriaService";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import ContentConfiguracoes from "../Components/ContentConfiguracoes";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import { columnsConfiguracoes } from "../Constants/Constants";
import AddIcon from "../../../components/Icon/AddIcon";
import ModalConfiguracoesTabs from "../Components/Modal/ModalConfiguracoesTabs";
import ProcedimentoList from "./Procedimentos.js/ProcedimentoList";
import ProdutoList from "../Components/Modal/Tabs/ProdutoList";
import TipoConsultaList from "../Components/Modal/Tabs/TipoConsultaList";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import WarningIcon from "../../../components/Icon/Warning";
import { TextField, TextFieldSearch } from '../../../components/TextField'
import localStorageService, { ACCESS_TOKEN_KEY } from "../../../services/storage";
import { Button } from "../../../components/ui/Buttons";

const tipos = {
    procedimentos: {
        name: "procedimento",
        text: 'procedimentos',
        atributoName: 'convenioProcedimentos',
        list: "procedimentos",
    },
    produto: {
        name: "produto",
        text: 'produtos',
        atributoName: 'produtoConvenios',
        list: "produtos",
    },
    agendamentoTipo: {
        name: "agendamentoTipo",
        text: 'tipos de consulta',
        atributoName: 'convenioAgendamentoTipo',
        list: "agendamentosTipo",
    }
};

const tabsModalSelected = [
    "procedimentos",
    "produto",
    "agendamentoTipo",
];

const tabsModal = {
    PROCEDIMENTO: 0,
    PRODUTO: 1,
    TIPO_CONSULTA: 2,
};

const propsTabsModalDefault = {
    options: ["Procedimento", "Produto", "Tipo de consulta"],
    tabSelected: tabsModal.PROCEDIMENTO,
    valueSearch: "",
    quantidades: [0, 0, 0],
};

@inject('convenioStore', 'googleAnalyticsStore', 'modalConfiguracoesStore', 'auditoriaStore')
@observer
class Convenios extends React.Component {
    state = {
        search: "",
        isLoading: false,
        convenios: [],
        convenioSelected: undefined,
        loadingSave: false,
        status: true,
        pageNumber: 0,
        last: false,
        tipoOpcao: "procedimento",
        procedimentos: [],
        produtos: [],
        agendamentosTipo: [],
        pageNumberModal: 0,
        loadingModal: false,
        lastModal: false,
        notification: {
            isOpen: false,
            message: "",
        },
        disableButton: false,
        ordenarTabela: {
            sortField: "descricao",
            sortDir: "ASC",
        },
        tabSelected: tabs.INFORMACOES,
        propsTabsModal: propsTabsModalDefault,
        openAlertConvenios: false,
        propsTabsAuditoria: {
            tabSelected: tabs.INFORMACOES
        },
    }
    componentDidMount() {
        const { modalConfiguracoesStore, auditoriaStore } = this.props;
        this.loadConvenios();
        const paginaVisualizada = {
            page: window.location.hash,
            title: 'Configurações - Convênio'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);
        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o convênio",
            auditoriaTela: "convenio",
        };

        modalConfiguracoesStore.titulo="convênio";
        modalConfiguracoesStore.opcao="convenio";
    };

    loadConvenios = () => {
        this.setState({ isLoading: true });
        findAllConvenio(
            this.state.search,
            {
                sortDir: this.state.ordenarTabela.sortDir,
                sortField: this.state.ordenarTabela.sortField
            },
            this.state.status,
            this.state.pageNumber)
            .then(({ data: response }) => {
                this.setState({
                    convenios: [...this.state.convenios, ...response.data.findAllConvenio.content],
                    last: response.data.findAllConvenio.last
                });
            })
            .catch(error => {
                this.showAlertMessage('Erro ao carregar a lista de procedimentos');
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    handleClickNovoConvenio = () => {
        this.setState({ 
            convenioSelected: { 
                descricao: '', 
                ativo: true,
                registroAns: '', 
                codigoUnidade: '', 
                convenioProcedimentos: [],
                produtoConvenios: [],
                convenioAgendamentoTipo: [],
                logoConvenio: null,
            },
            tipoOpcao: "procedimentos",
            propsTabsModal: propsTabsModalDefault,
            procedimentos: [],
            produtos: [],
            agendamentosTipo: [],
            pageNumberModal: 0

        }, async() => {
            await this.loadProcedimentos();
            this.create();
        })
    };

    handleChangeStatus = (e) => {
        const { convenioSelected } = this.state;
        this.setState({
            convenioSelected: {
                ...convenioSelected,
                ativo: !convenioSelected.ativo
            }
        });
    };

    handleClickSearch = () => {
        this.setState({
            convenios: [],
            pageNumber: 0
        }, () => {
            this.loadConvenios();
        });
        
    };

    handleClickClear = () => {
        this.setState({
            search: "",
            convenios: [],
            pageNumber: 0,
        }, () => {
            this.loadConvenios();
        });
        
    };

    onChangeSearch = (e) => {
        let search = e.target.value;
        this.setState({ search })
    };

    onKeypressSearch = (e) => {
        if (e.key === 'Enter' && this.state.search.length >= 3) {
            this.setState({
                convenios: [],
                pageNumber: 0,
            }, () => {
                this.loadConvenios();
            });
        }
    };

    onCloseModal = () => {
        const { modalConfiguracoesStore } = this.props;

        modalConfiguracoesStore.showModal = false;

        this.setState({ 
            convenioSelected: undefined,
            procedimentos: [],
            pageNumberModal: 0, 
            propsTabsModal: propsTabsModalDefault,
        });
    };

    create = () => {
        const { modalConfiguracoesStore } = this.props;
        db.get("usuarioLogado", "{}").then(usuarioLogado => {
            const unidade = JSON.parse(usuarioLogado).unidadeAtual;

            modalConfiguracoesStore.showModal = true;
            this.setState({ 
                convenioSelected: { ...this.state.convenioSelected, unidade },
            });
        })
    };

    handleClickRow = (id) => {
        this.handleEditar(id);
    };

    handleEditar = async (id, isDuplicar=false) => {
        const { modalConfiguracoesStore } = this.props;

        const selecionado = await findByIdConvenio(id);
        const { convenioSistema } = selecionado || {};
        const { id: idConvenioSistema, convenioLogo } = convenioSistema || {};

        const convenioProcedimentosValoresComVirgula = selecionado.convenioProcedimentos.map(item => {
            return({
                ...item,
                valor: item.valor ? String(item.valor.toFixed(2)).replace('.', ',') : item.valor
            })
        })

        const produtoConveniosMascaraValor = selecionado.produtoConvenios.map(item => {
            return(
                {
                    ...item,
                    valor: item.valor ? String(item.valor.toFixed(2)).replace('.', ',') : item.valor
                }
            )
        });

        const convenioAgendamentoTipoValor = selecionado.convenioAgendamentoTipo.map(item => {
            return(
                {
                    ...item,
                    valor: item.valor ? String(item.valor.toFixed(2)).replace('.', ',') : item.valor
                }
            )
        });

        const urlDownload = idConvenioSistema && convenioLogo?.urlDownload && await this.getUrlDownloadLogo(convenioLogo?.urlDownload);

        const selecionadoAdaptado = {
            ...selecionado,
            convenioProcedimentos: convenioProcedimentosValoresComVirgula,
            produtoConvenios: produtoConveniosMascaraValor,
            convenioAgendamentoTipo: convenioAgendamentoTipoValor,
            logoConvenio: urlDownload,
            id: id,
        }

        if (isDuplicar) {
            selecionadoAdaptado.id=null;
            selecionadoAdaptado.descricao=`${selecionadoAdaptado.descricao} - cópia`;
            selecionadoAdaptado.convenioSistema = null;
        };

        this.setState({ 
            convenioSelected: selecionadoAdaptado,
            procedimentos: [],
            produtos: [],
            pageNumberModal: 0,
            tipoOpcao: "procedimentos",
            propsTabsModal: {
                ...propsTabsModalDefault,
                quantidades: [ selecionadoAdaptado.convenioProcedimentos.length, selecionadoAdaptado.produtoConvenios.length, selecionadoAdaptado.convenioAgendamentoTipo.length ]
            },
            propsTabsAuditoria: {
                tabSelected: 0
            },
        }, async() => {
            await this.loadProcedimentos();
            modalConfiguracoesStore.showModal = true;

        })
    }

    getUrlDownloadLogo = async(logoConvenio) => {
        const token = await localStorageService.get(ACCESS_TOKEN_KEY);
        const urlLogo = buildUrlDownload(logoConvenio, token);

        return urlLogo;
    }

    handleClickOrdenar = async (value) => {
        const { ordenarTabela } = this.state;
        
        const sortDir = ordenarTabela.sortField !== value ? "ASC" : (ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC")

        const status = value === 'ativo' ? sortDir === 'ASC' : this.state.status;

        this.setState({            
            ordenarTabela: { 
              sortDir: sortDir,
              sortField: value,
            },
            convenios: [],
            pageNumber: 0,
            status
        }, () => this.loadConvenios());
    }

    handleClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.convenioStore.closeNotification();
    };
    handleDuplicar = async (id) => {
        this.handleEditar(id, true);
    }

    handleAtivar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdConvenio(id);
        const convenioSistema = selecionado.convenioSistema?.id && {
            id: selecionado.convenioSistema?.id
        }

        const selecionadoComId = {
            ...selecionado,
            id,
            ativo: true,
            convenioSistema,
        }
        await saveConvenio(selecionadoComId);
        this.setState({
            convenios: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadConvenios();
    };

    handleInativar = async (id) => {
        const { modalConfiguracoesStore } = this.props;
        const selecionado = await findByIdConvenio(id);
        const convenioSistema = selecionado.convenioSistema?.id && {
            id: selecionado.convenioSistema?.id
        }
        const selecionadoComId = {
            ...selecionado,
            id,
            ativo: false,
            convenioSistema,
        }
        await saveConvenio(selecionadoComId);
        this.setState({
            convenios: [],
            pageNumber: 0
        });
        modalConfiguracoesStore.showModal=false;
        this.loadConvenios();
    };

    loadMore = () => {
        const { isLoading, convenios } = this.state;
        if (isLoading) {
            return;
        }

        this.setState({ pageNumber: convenios.length > 0 ? this.state.pageNumber + 1 : 0 });

        this.loadConvenios();
    };

    loadMoreModal = () => {
        const { loadingModal, pageNumberModal, tipoOpcao } = this.state;

        if (loadingModal) {
            return;
        };

        const listaOpcao = tipos[tipoOpcao].text;

        this.setState({ pageNumberModal: [listaOpcao].length > 0 ? pageNumberModal + 1 : 0 },
            () => this.loadItensTipoOpcao()
        );
    };

    handleChange = (event, itemLista, index) => {
        const { name, value } = event.target;
        const { convenioSelected, tipoOpcao } = this.state;

        const tipo = tipos[tipoOpcao];
        
        if(!!itemLista) {
            const idItemLista = itemLista[tipoOpcao]?.id;
            itemLista.valor = value;
            const atributoTipo = tipo.atributoName;
            const indexSelecionado = convenioSelected[atributoTipo].findIndex(convenio => convenio[tipo.name]?.id === idItemLista);
            
            if(indexSelecionado >= 0) {
                let convenioSelectedModify = convenioSelected;
                convenioSelectedModify[atributoTipo][indexSelecionado].valor = itemLista.valor;
                this.setState({convenioSelected: convenioSelectedModify});
            }

        } else {
            this.setState(prevState => ({
                convenioSelected: {
                    ...prevState.convenioSelected,
                    [name]: value
                },
            }));
        };

        if(name === "valor" || name === "quantidade") {
            this.setState({ convenio: 
                convenioSelected,
                [tipo.list]: [
                    ...this.state[tipo.list].slice(0, index),
                    {
                      ...this.state[tipo.list][index],
                      [name]: value,
                    },
                    ...this.state[tipo.list].slice(index+1, this.state[tipo.list].length)
                ]
            })
        }
    };

    handleChangeCheckbox = (item, index) => {
        const { tipoOpcao, convenioSelected } = this.state;

        let convenioSelectedModify = convenioSelected;
        const tipo = tipos[tipoOpcao]

        if(!item[tipoOpcao].checked) {
            convenioSelectedModify[tipo.atributoName].push({
                [tipo.name]: {
                    id: item[tipoOpcao].id
                },
                valor: item.valor
            });
        } else {
            const listaItemRemovido = convenioSelectedModify[tipo.atributoName].filter(convenio => convenio[tipo.name].id !== item[tipoOpcao].id);
            convenioSelectedModify[tipo.atributoName] = listaItemRemovido;
        };

        this.getQuantidade(item);

        let itemModificado = this.state[tipo.list][index];
        itemModificado[tipoOpcao].checked = !item[tipoOpcao].checked;

        this.setState({
            convenioSelected: convenioSelectedModify,
            [tipo.list]: 
            [
                ...this.state[tipo.list].slice(0, index),
                itemModificado,
                ...this.state[tipo.list].slice(index+1, this.state[tipo.list].length)
            ]
        })
    };

    getQuantidade = (item) => {
        const { tipoOpcao, propsTabsModal } = this.state;

        const quantidades = {
            procedimentos: [
                !item[tipoOpcao].checked ? propsTabsModal.quantidades[0]+1 : propsTabsModal.quantidades[0]-1, 
                propsTabsModal.quantidades[1], 
                propsTabsModal.quantidades[2]
            ],
            produto: [
                propsTabsModal.quantidades[0],
                !item[tipoOpcao].checked ? propsTabsModal.quantidades[1]+1 : propsTabsModal.quantidades[1]-1,
                propsTabsModal.quantidades[2]
            ],
            agendamentoTipo: [
                propsTabsModal.quantidades[0],
                propsTabsModal.quantidades[1],
                !item[tipoOpcao].checked ? propsTabsModal.quantidades[2]+1 : propsTabsModal.quantidades[2]-1
            ],
        };

        this.setState({ 
            propsTabsModal: {
                ...propsTabsModal,
                quantidades: quantidades[tipoOpcao]
            }
        });
    };

    changeStateForLoading = (message) => {
        this.setState({ 
            disableButton: false,
            loadingSave: false,
        })
        this.showAlertMessage(message);
    }

    handleSave = async ({isSaveMesmoNome=false}) => {
        const { modalConfiguracoesStore } = this.props;
        const { convenioSelected:convenio } = this.state;
        const { logoConvenio } = convenio;
        try {
            let convenioSelected = {
                ...convenio,
                logoConvenio: logoConvenio?.id,
                logoTipo: logoConvenio?.logoTipo,
            };

            this.setState({ 
                disableButton: true,
                loadingSave: true,
            })
            if(!convenioSelected?.descricao || string.isEmpty(convenioSelected?.descricao)) {
                this.changeStateForLoading('Digite o nome do convênio.')
                return
            }
            if(convenioSelected?.registroAns !== "" && convenioSelected?.registroAns?.length < 6 ) {
                this.changeStateForLoading('Registro ANS inválido.')
                return
            }
    
            const existeMesmoNome = await this.verificaConvenioComMesmoNome(convenioSelected.descricao)
    
            if(!isSaveMesmoNome && existeMesmoNome) return;
          
            convenioSelected.registroAns === "" && delete convenioSelected.registroAns
            convenioSelected.codigoUnidade === "" && delete convenioSelected.codigoUnidade
    
            const convenioProcedimentosRetiraMascaraValor = convenioSelected.convenioProcedimentos.map(item => {
                delete item.valores;
                delete item.valorVigente;
                return(
                    {
                        ...item,
                        valor: string.removeMask(String(item.valor), "R$")
                    }
                )
            });
    
            const produtoConveniosMascaraValor = convenioSelected.produtoConvenios.map(item => {
                return(
                    {
                        ...item,
                        valor: string.removeMask(String(item.valor), "R$")
                    }
                )
            });
    
            const convenioAgendamentoTipoValor = convenioSelected.convenioAgendamentoTipo.map(item => {
                return(
                    {
                        ...item,
                        valor: string.removeMask(String(item.valor), "R$")
                    }
                )
            });
    
            convenioSelected = {
                ...convenioSelected,
                convenioProcedimentos: convenioProcedimentosRetiraMascaraValor,
                produtoConvenios: produtoConveniosMascaraValor,
                convenioAgendamentoTipo: convenioAgendamentoTipoValor,
                convenioSistema: convenioSelected?.convenioSistema && { id: convenioSelected?.convenioSistema?.id }
            };
    
            await saveConvenio(convenioSelected);
            this.setState({
                convenios: [],
                pageNumber: 0,
            }, () => this.loadConvenios());
            modalConfiguracoesStore.showModal= this.state.openAlertProcedimentos;
        } catch(e){
            this.showAlertMessage('Erro ao salvar o convênio');
        } finally {
            this.setState({
                disableButton: false,
                loadingSave: false,
            });
        }
    };

    loadListaModal = (value) => {
        switch (value) {
            case "procedimentos":
                this.loadProcedimentos();
                break;
            case "produto":
                this.loadProdutos();
                break;
            case "agendamentoTipo":
                default:
                this.loadAgendamentoTipo();
                break;
        }
    };

    onChangeSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        let value = e.target.value;
        this.setState({ propsTabsModal: {
            ...propsTabsModal,
            valueSearch: value
        } })
    };

    handleClickClearModal = async () => {
        this.setState({
            propsTabsModal: {
                ...this.state.propsTabsModal,
                valueSearch: "",
            },
            procedimentos: [],
            produtos: [],
            agendamentosTipo: [],
            pageNumberModal: 0,
        }, () => {
            this.loadItensTipoOpcao();
        });
    };

    loadItensTipoOpcao = () => {
        const { tipoOpcao } = this.state;

        const loadList = {
            procedimentos: this.loadProcedimentos(),
            produto: this.loadProdutos(),
            agendamentoTipo: this.loadAgendamentoTipo(),
        };

        return loadList[tipoOpcao];
    };

    onKeypressSearchModal = (e) => {
        const { propsTabsModal } = this.state;

        if (e.key === 'Enter' && propsTabsModal.valueSearch.length >= 3) {
            this.setState({
                procedimentos: [],
                produtos: [],
                agendamentosTipo: [],
                pageNumberModal: 0,
            }, () => {
                this.loadItensTipoOpcao();
            });
        }
    };

    handleClickSearchModal = () => {
        this.setState({
            procedimentos: [],
            produtos: [],
            agendamentosTipo: [],
            pageNumberModal: 0,
        }, () => {
            this.loadItensTipoOpcao();
        });
    }

    loadProcedimentos = async () => {
        const { propsTabsModal, pageNumberModal, convenioSelected } = this.state;
        this.setState({ loadingModal: true });
        await findAllProcedimentos({
            nome: propsTabsModal.valueSearch,
            ativo: true,
            pageableDTO:{
                pageNumber: pageNumberModal,
                pageSize: 10,
                sortDir: "ASC",
                sortField: "nome"
            }
        })
            .then(({ data: response }) => {
                const procedimentos = response.data.findAllProcedimentos.content.map(procedimentoItem => {
                    const procedimentoChecked = convenioSelected.convenioProcedimentos.find(item => item.procedimento?.id === procedimentoItem.id);
                    const dadosVigente = procedimentoItem.convenioProcedimentos.find(item => item.convenio.id === convenioSelected.id)
                    return {
                        procedimentos: {
                            nome: procedimentoItem.nome,
                            descricao: procedimentoItem.descricao,
                            id: procedimentoItem.id,
                            checked: !!procedimentoChecked
                        },
                        valor: procedimentoChecked?.valor || 0,
                        vigencia: {
                            ...dadosVigente?.valorVigente
                        }
                    }
                })

                this.setState({
                    procedimentos: [...this.state.procedimentos, ...procedimentos],
                    lastModal: response.data.findAllProcedimentos.last
                })
            })
            .catch(
                error => {
                    this.showAlertMessage('Erro ao carregar a lista de procedimentos');
                    
                })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    };

    loadProdutos = async() => {
        const { propsTabsModal, pageNumberModal, convenioSelected } = this.state;
        this.setState({ loadingModal: true });
        await findAllProduto({
            search: propsTabsModal.valueSearch,
            ativo: true,
            pageableDTO:{
                pageNumber: pageNumberModal,
                pageSize: 10,
                sortDir: "ASC",
                sortField: "nome"
            }
        })
            .then(({ data: response }) => {
                const produtos = response.data.findAllProduto.content.map(produtoItem => {
                    const produtoChecked = convenioSelected.produtoConvenios.find(item => item.produto.id === produtoItem.id);
                    return {
                        produto: {
                            nome: produtoItem.nome,
                            descricao: produtoItem.descricao,
                            id: produtoItem.id,
                            checked: !!produtoChecked
                        },
                        valor: produtoChecked?.valor || 0
                    }
                })


                this.setState({
                    produtos: [...this.state.produtos, ...produtos],
                    lastModal: response.data.findAllProduto.last
                })
            })
            .catch(
                error => {
                    this.showAlertMessage('Erro ao carregar a lista de procedimentos');
                    
                })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    }

    loadAgendamentoTipo = async() => {
        const { propsTabsModal, pageNumberModal, convenioSelected } = this.state;
        this.setState({ loadingModal: true });

        await findAllAgendamentoTipoSearch(propsTabsModal.valueSearch, pageNumberModal)
        .then(({ data: response }) => {
            const tiposConsulta = response.data.findAllAgendamentoTipoPage.content.map(tipoConsultaItem => {
                const tipoConsultaChecked = convenioSelected.convenioAgendamentoTipo.find(item => item.agendamentoTipo?.id === tipoConsultaItem.id);
                
                    return {
                        agendamentoTipo: {
                            descricao: tipoConsultaItem.descricao,
                            id: tipoConsultaItem.id,
                            checked: !!tipoConsultaChecked
                        },
                        valor: tipoConsultaChecked?.valor || 0
                    }
                })

                const items = pageNumberModal === 0 ? [...tiposConsulta] : [...this.state.agendamentosTipo, ...tiposConsulta];

                this.setState({
                    agendamentosTipo: items,
                    lastModal: response.data.findAllAgendamentoTipoPage.last
                })
            })
            .catch(
                error => {
                    console.log('error', error)
                    this.showAlertMessage('Erro ao carregar a lista de tipos de consulta.');
                    
                })
            .finally(() => {
                this.setState({ loadingModal: false })
            })
    }

    showAlertMessage = (message) => { 
        this.setState({ notification: {isOpen: true, message} });
        const timeoutId = setTimeout(() => { 
            this.closeAlertMessage(); clearTimeout(timeoutId); 
        }, 3000); 
    };

	closeAlertMessage = () => {
		const notification = {
			isOpen: false,
			message: "",
		};
		this.setState({ notification: notification });
	};

    changeTabSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { convenioSelected } = this.state;

        auditoriaStore.auditoriaProps={
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        }

        if(tabSelected === 1) {
            auditoriaStore.auditoriaProps={
                ...auditoriaStore.auditoriaProps,
                auditoria: "o convênio",
                auditoriaTela: "convenio",
            };    

            auditoriaStore.load={
                query: findAllConvenioAuditByConvenioId, 
                variableName: "convenioId",
                variableId: convenioSelected?.id, 
            }
            await auditoriaStore.loadAuditItems();
        }

        this.setState({ tabSelected })
    };

    handleConvenioSistemaLoadOptions = async(
        search,
        loadedOptions,
        { page }) => {
            return this.handleLoadMoreOptions({
                search,
                loadedOptions,
                data: { page },
            });
    };

    handleLoadMoreOptions = async ({ search, data }) => {
        const response = await findAllConvenioSistemaByConvenio({
            search,
            vinculado: false,
            pageableDTO: {
                sortDir: "ASC", 
                sortField: "descricao",
                pageNumber: data.page,
                pageSize: 30
            },
        });
    
        return {
          options: response.content,
          hasMore: !response.last,
          additional: {
            page: data.page + 1,
          },
        };
    };

    handleChangeConvenioSistema = async(e) => {
        const logoConvenio = e?.convenioLogo ? await this.getUrlDownloadLogo( e.convenioLogo.urlDownload) : null;

        this.setState(prevState => ({
            convenioSelected: {
                ...prevState.convenioSelected,
                convenioSistema: e,
                logoConvenio,
            },
        }));
    };

    setAuditoriaTela = () => {
        const { auditoriaStore } = this.props;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            auditoria: "o convênio",
            auditoriaTela: "convenio",
        };

        auditoriaStore.load={
            query: findAllConvenioAuditByUnidade, 
            variableName: "",
            variableId: "", 
        };
    };

    changeTabModalAuditoriaSelected = async(tabSelected) => {
        const { auditoriaStore } = this.props;
        const { convenioSelected } = this.state;

        auditoriaStore.auditoriaProps={
            ...auditoriaStore.auditoriaProps,
            pageNumber: 0,
            lastPage: false,
            auditorias: [],
        };

        if (tabSelected === 1) {
            auditoriaStore.load={
                query: findAllConvenioAuditByConvenioId, 
                variableName: "convenioId",
                variableId: convenioSelected?.id,
            }
            await auditoriaStore.loadAuditItems();
          }

        this.setState({
            propsTabsAuditoria: {
                tabSelected: tabSelected
            }
        })
    };

    changeTabSelectedModal = async(tabSelected) => {
        const { propsTabsModal } = this.state;

        this.setState(
            {
              procedimentos: [],
              profissionaisSaude: [],
              pageNumberModal: 0,
              lastModal: false,
              tipoOpcao: tabsModalSelected[tabSelected],
              propsTabsModal: {
                ...propsTabsModal,
                tabSelected: tabSelected,
                valueSearch: "",
            }
            },
            () => this.loadItensTipoOpcao()
        );
    };

    renderTabModal = () => {
        const { produtos, procedimentos, agendamentosTipo, lastModal, loadingModal, propsTabsModal ,convenioSelected} = this.state;

        const propsComponent = {
            handleChangeCheckbox: this.handleChangeCheckbox,
            handleChange: this.handleChange,
            loadMore: this.loadMoreModal,
            lastScroll: lastModal,
            loadingModal: loadingModal,
            convenioSelected:convenioSelected,
        }

        const tabsModal = [
            <ProcedimentoList
                {...propsComponent}
                listaModal={procedimentos}
            />,
            <ProdutoList
                {...propsComponent}
                showValor
                listaModal={produtos}
            />,
            <TipoConsultaList
                {...propsComponent}
                listaModal={agendamentosTipo}
            />
        ];

        return tabsModal[propsTabsModal.tabSelected];
    };

    renderHeader = () => {
        const { classes } = this.props;
        const { convenioSelected } = this.state;
        const { id, descricao, ativo, registroAns, codigoUnidade, convenioSistema, logoConvenio } = convenioSelected || {};

        return(
            <div className={classes.header}>
                <div className={classes.rowHeader}>
                    <div className={classes.contentFieldHeader}>
                        <div className={classes.titleRenderHeader}>
                        Nome do convênio *Obrigatório
                        </div>
                        <TextField
                        name={"descricao"}
                        className={classes.textField}
                        classes={{
                            root: classes.notchedOutlineTextField,
                        }}
                        placeholder={`Digite o nome do convênio`}
                        value={descricao}
                        onChange={this.handleChange}
                        />
                    </div>
                    <div>
                    {id && (
                        <div className={classes.contentFieldHeader}>
                            <div className={classes.titleRenderHeader}>Status</div>
                            <Switch
                                checked={ativo}
                                color="primary"
                                onClick={this.handleChangeStatus}
                            />
                        </div>
                    )}
                    </div>
                </div>

                <div className={classes.rowHeader}>
                    <div className={classes.contentFieldHeaderRegistroANS}>
                        <div className={classes.titleRenderHeader}> Registro ANS </div>
                        <TextField
                            name='registroAns'
                            className={classes.renderHeaderInput}
                            value={registroAns}
                            onChange={e => e.target.value.length <= 6 && this.handleChange(e)}
                            type='number'
                            placeholder='número do registro'
                        />
                    </div>
                    <div className={classes.contentFieldHeader}>
                        <div className={classes.titleRenderHeader}> Código na operadora </div>
                        <TextField
                            name='codigoUnidade'
                            className={classes.renderHeaderInput}
                            value={codigoUnidade}
                            onChange={e => e.target.value.length <= 14 && this.handleChange(e)}
                            type='number'
                            placeholder='número do código'
                        />
                    </div>
                    <div className={classes.contentFieldHeader}>
                        <div className={classes.titleRenderHeader}> Operadora </div>
                        <TextFieldSearch
                            name='convenioSistema'
                            placeholder='Selecione'
                            classNotched={classes.notchedOutline}
                            className={classes.renderHeaderSelect}
                            loadOptions={this.handleConvenioSistemaLoadOptions}
                            withPaginate
                            value={convenioSistema}
                            onChange={e => this.handleChangeConvenioSistema(e)}
                            debounceTimeout={300}
                            additional={{
                                page: 0,
                            }}
                        />
                    </div>
                    <div className={classes.contentFieldHeaderLogo}>
                    {logoConvenio && <img src={logoConvenio} alt="logo" />}
                    </div>
                </div>
            </div>
        )
    };

    saveConvenioComMesmoNome = () => {
        const { convenioSelected } = this.state;

        this.setState({
            convenioSelected: {
                ...convenioSelected,
                descricao: `${convenioSelected.descricao} - cópia`
            }
        }, () => {
            this.closeModalConfirmacao();
            this.handleSave({isSaveMesmoNome: true});
        });
    };

    closeModalConfirmacao = () => {
        this.setState({ openAlertConvenios: false })
    }

    voltarMudarNome = () => {
        const { modalConfiguracoesStore } = this.props;
        this.closeModalConfirmacao();
        modalConfiguracoesStore.showModal=true;
    };

    verificaConvenioComMesmoNome = async(nomeProcedimento) => {
        const { convenioSelected } = this.state;
        const existeMesmoNome = await convenioNomeJaExistente(nomeProcedimento);
        this.setState({ 
            openAlertConvenios: existeMesmoNome && !convenioSelected?.id,
        })

        return existeMesmoNome && !convenioSelected?.id;
    };

    render() {
        const { classes, modalConfiguracoesStore } = this.props;
        const { notification, convenios, last, isLoading, search, ordenarTabela, propsTabsModal, convenioSelected, loadingSave } = this.state;

        return (
            <>
                <div className={classes.content}>
                    <PageTitle title={pageConfiguracoes("Convênios")}/>
                    <HeaderConfiguracoesButtonNovo
                        title="Convênios"
                        labelButton="Novo Convênio"
                        handleClickNovo={this.handleClickNovoConvenio}
                        search={{
                            handleChange: this.onChangeSearch,
                            value: search,
                            onKeypress: this.onKeypressSearch,
                            handleClickClear: this.handleClickClear,
                            onClickButtonSearch: this.handleClickSearch
                        }}
                    />
                    <div className={classes.contentList}>
                        <ContentConfiguracoes
                            quantidadeProcedimentos={convenios.length}
                            setAuditoriaTela={this.setAuditoriaTela}
                        >
                            <div className={classes.table}>
                                <Scroll
                                    loadMore={this.loadMore}
                                    hasMore={!last}
                                    pageStart={0}
                                    initialLoad={false}
                                >
                                    {
                                        convenios.length > 0 && <Table
                                            dados={convenios}
                                            columns={columnsConfiguracoes({
                                                isDescricao: true,
                                                headerNome: "Nome do convênio",
                                                functions: {
                                                    handleEditar: this.handleEditar,
                                                    handleDuplicar: this.handleDuplicar,
                                                    handleDesativar: this.handleInativar,
                                                    handleAtivar: this.handleAtivar,
                                                },
                                            })}
                                            clickable={true}
                                            handleClick={this.handleClickRow}
                                            comOrdenacao
                                            ordenarTabela={ordenarTabela}
                                            handleClickOrdenar={(value) => this.handleClickOrdenar(value)} 
                                        />
                                    }
                                    {
                                        isLoading && (
                                            <div className={classes.notFoundContainer}>
                                                <CircularProgress/>
                                            </div>
                                        )
                                    }
                                </Scroll>
                            </div>
                        </ContentConfiguracoes>
                        <Button
                            shadow
                            shape='circle'
                            bgColor='#26ACA9'
                            onClick={this.handleClickNovoConvenio}
                            className={classes.buttonCriar}
                        >
                            <AddIcon/>
                        </Button>
                        {
                             modalConfiguracoesStore.showModal &&
                             <ModalConfiguracoesTabs
                                itemSelecionado={convenioSelected}
                                name={"descricao"}
                                title={"Convênio"}
                                handleChange={this.handleChange}
                                renderHeader={this.renderHeader}
                                handleChangeStatus={this.handleChangeStatus}
                                loadingSave={loadingSave}
                                classes={{
                                    content: convenioSelected?.id ? classes.contentModalEdit : classes.contentModal
                                }}
                                onClose={this.onCloseModal}
                                handleSave={this.handleSave}
                                loadingModal={this.state.loadingModal}
                                propsTabs={{
                                    ...propsTabsModal,
                                    changeTabSelected: this.changeTabSelectedModal,
                                    handleClickSearch: this.handleClickSearchModal,
                                    handleClickClear: this.handleClickClearModal,
                                    onChangeSearch: this.onChangeSearchModal,
                                    onKeypressSearch: this.onKeypressSearchModal,
                                }}
                                propsPopperCustom={{
                                    handleEditar: this.handleEditar,
                                    handleDuplicar: this.handleDuplicar,
                                    handleDesativar: this.handleInativar,
                                    handleAtivar: this.handleAtivar,
                                }}
                                propsTabsAuditoria={{
                                    tabSelected: this.state.propsTabsAuditoria.tabSelected,
                                    changeTabAuditoriaSelected: this.changeTabModalAuditoriaSelected,
                                }}
                             >
                                { this.renderTabModal() }
                             </ModalConfiguracoesTabs>
                        }

                    </div>
                </div>
                <ModalConfirmacao
                    open={this.state.openAlertConvenios}
                    classes={{
                        paperAlert: classes.paperAlert
                    }}
                    icon={<WarningIcon />}
                    color={"yellow"}
                    title={" Já foi criado um convênio com esse nome"}
                    buttons={{
                        labelPrimaryButton: "Salvar assim mesmo",
                        handlePrimaryButton: () => this.saveConvenioComMesmoNome(),
                        labelSecondButton: "Voltar e alterar nome",
                        handleSecondButton: () => this.voltarMudarNome(),
                        disabledPrimaryButton: this.state.loadingSave,
                        disabledSecondButton: this.state.loadingSave,
                    }}
                />
                <Notification
                    close={this.closeAlertMessage}
					reset={this.closeAlertMessage}
					isOpen={notification.isOpen}
					variant={"error"}
					message={notification.message}
                />
            </>
        )
    }
};

export default withStyles(theme => ({
    content: {
        background: "#F5F5F5",
        "& #buttonCircle": {
            boxShadow: 'none',
            background: "#fff",
            color: "#5F6368",
        },
    },
    contentModal: {
        height: "calc(100% - 238px) !important",
    },
    contentModalEdit: {
        height: "calc(100% - 278px)",
    },
    contentList: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        fontWeight: 600,
        alignItems: 'center',
        padding: "24px 24px 0",
        gap: '16px',
        height: "calc(100% - 104px)",   
    },
    table: {
        "&>div": {
            height: 'calc(100vh - 240px)',
            overflow: 'auto',
        },
        "& thead th": {
            textAlign: "left",
            top: 0,
            position: "sticky",
            zIndex: 1,
        },
    },
    notFoundContainer: {
        width: '100%',
        display: "flex",
        justifyContent: "center",
    },
    buttonCriar: {
        position: 'absolute',
        bottom: 88,
        right: 37,
        zIndex: 100
    },
    paperAlert: {
        maxWidth: "373px",
    },
    rowHeader: {
        display: "flex",
        flexDirection: "row",
        gap: "8px",
        marginTop: "8px",
    },
    titleRenderHeader: {
        color: "#868686",
        fontSize: "12px",
    },
    renderHeaderSelect: {
        "&>div": {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 600,
            color: '#868686',
            "&>div": {
                height: '38px',
                background: '#f2f2f2',
                borderRadius: '8px',
                border: '0.5px solid rgba(220, 220, 220, 0.2)',
            },
        },
        "& p": {
            fontWeight: '600',
            color: '#868686'
        },
    },
    notchedOutline: {
        border: 'none',
    },
    renderHeaderInput: {
        height: '38px',
        border: '0.5px solid rgba(220, 220, 220, 0.2)',
        borderRadius: '8px',
        background: '#F2F2F2',
        color: '#868686',
        justifyContent: 'center',
        "&>div": {
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: 600,
            "& input": {
                fontWeight: '600',
                color: '#868686'
            },
        },
        "& fieldset": {
            border: 'none',
        },
        "& ::placeholder": {
            color: '#000'
          },
    },
    contentFieldHeader: {
        width: "100%",
    },
    contentFieldHeaderRegistroANS: {
        width: "100%",
        maxWidth: '100px',
    },
    contentFieldHeaderLogo: {
        width: "100%",
        maxWidth: '150px',
        alignSelf: 'self-end',
        textAlign: '-webkit-center',
    },
    textField: {
        height: "40px",
        width: "100%",
        "& input": {
          height: "23px",
          background: "#F2F2F2",
          border: "0.5px solid rgba(220, 220, 220, 0.2)",
          borderRadius: "8px",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontWeight: 600,
          color: '#868686',
        },
        "& ::placeholder": {
          color: '#000'
        },
    },
    notchedOutlineTextField: {
        "& fieldset": {
            border: "none",
        },
    },
}))(Convenios);
