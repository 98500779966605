import React, { useState, useEffect, useReducer } from 'react'

import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite';
import styles from './styles';

import PacientesHeader from './components/PacientesHeader';
import { withStyles } from "@material-ui/core/styles/index"
import { Add } from '@material-ui/icons';
import Footer from "../../../components/Footer/Footer"

import { sujeitoAtencaoCsv, ativarSujeitoAtencao, desativarSujeitoAtencao, findAllCampoPacienteByProfissionalSaudeLogadoList } from '../../../services/SujeitoAtencaoService';
import { base64Convert } from '../../../utils/base64ToCsv';
import PageTitle from '../../../components/PageTitle/PageTitle';
import PacienteContent from './components/PacienteContent';
import Filter from './components/Filter';
import  { getDynamicColumns } from './utils/getTableColumns';
import FilterModal from './components/Modais/FilterModal';
import Notification from '../../../components/Notification';
import PersonalizarColunas from './components/Modais/PersonalizarColunas';
import { checkUserRole } from '../../../utils/checkUserRole';
import { Button } from '../../../components/ui/Buttons';
import ArrowDownloadIcon from '../../../components/Icon/ArrowDownload';

const SujeitosAtencao = observer((props) => {
	const { 
		sujeitoAtencaoStore, 
		usuarioStore, 
		profissionalSaudeStore, 
		unidadeStore,
		googleAnalyticsStore,
		controleSessaoStore,
		history, 
		classes 
	} = props;

	const [unidadeLogada, setUnidadeLogada] = useState(null);
	const [elements, setElements] = useState([]);
	const [profissionaisSaude, setProfissionaisSaude] = useState([]);
	const [openFilterModal, setOpenFilterModal] = useState(false);
	const [loadingCsv, setLoadingCsv] = useState(false);
	const [canUpdateStatus, setCanUpdateStatus] = useState(false);
	const [sujeitoAtencaoQuantidadeTotal, setSujeitoAtencaoQuantidadeTotal] = useState(0);

	const [allCamposSelected, setAllCamposSelected] = useState([]);

	const [openPersonalizarColunas, togglePersonalizarColunas] = useReducer((open) => !open, false);

	const profissionalLogado = JSON.parse(localStorage[`_immortal|profissional_saude_logado`] || null);

	useEffect(() => {
		getUnidadeLogada();
		checkUsoTelefoneInternacional();
		registerViewPageInGoogleAnalytics();
		loadSujeitosAtencao();
		controleSessaoStore.resetParamsPacote();
		loadQuantidadeSujeitoAtencao();

		return () => {
			sujeitoAtencaoStore.resetFilter();
		}
	}, []);

	useEffect(() => {
		if (unidadeLogada) {
			loadProfissionaisSaude();
		}
	}, [unidadeLogada]);

	useEffect(() => {
		getCamposSelected();
	}, []);

	const getCamposSelected = async () => {
		const response = await findAllCampoPacienteByProfissionalSaudeLogadoList();
		setAllCamposSelected(response);
	}

	const getUnidadeLogada = async () => {
		const unidadeLogada = await usuarioStore.getUnidadeAtual();
		setUnidadeLogada(unidadeLogada);
	};

	const loadQuantidadeSujeitoAtencao = async () => {
		try {
			const quantidadeSujeitoAtencaoCadastrado = await sujeitoAtencaoStore.loadQuantidadeSujeitosCadastrados();
			setSujeitoAtencaoQuantidadeTotal(quantidadeSujeitoAtencaoCadastrado);
		} catch(error) {
			sujeitoAtencaoStore.openNotification(error.message, 'error');
		}
	};

	const loadSujeitosAtencao = async () => {
		const filter = sujeitoAtencaoStore.sujeitoAtencaoFilter;
		sujeitoAtencaoStore.isLoadingTable = true;

		try {
			const response = await sujeitoAtencaoStore.consultaSujeitosDeAtencao(filter);
			const updatedElements = filter.pageNumber === 0 ? response.content : [...elements, ...response.content];
			setElements(updatedElements);

			sujeitoAtencaoStore.hasMoreToLoad = !response?.last;
			if (typeof response?.last === 'boolean' && !response?.last) {
				sujeitoAtencaoStore.sujeitoAtencaoFilter = {
					...filter,
					pageNumber: filter.pageNumber + 1
				};
			}

			sujeitoAtencaoStore.isLoadingTable = false;
		} catch (error) {
			sujeitoAtencaoStore.openNotification('Falha ao carregar os pacientes', 'error');
			sujeitoAtencaoStore.isLoadingTable = false;
		}
	};

	const loadProfissionaisSaude = async () => {
		const listaProfissinalSaude = await profissionalSaudeStore.findByUnidadeComAgenda(unidadeLogada?.id);
		setProfissionaisSaude(listaProfissinalSaude);
	};

	const checkUsoTelefoneInternacional = async () => {
		if (!unidadeStore.unidade.id) {
			await unidadeStore.checkUsoTelefoneInternacional();
		}
		sujeitoAtencaoStore.unidadeUtilizaTelefoneInternacional = unidadeStore.unidade.utilizaTelefoneInternacional;
	};

	const registerViewPageInGoogleAnalytics = () => {
		const viewedPage = {
			page: window.location.hash,
			title: 'Pacientes'
		}
		googleAnalyticsStore.pageView(viewedPage);
	}

	const handleClickNewPaciente = () => {
		sujeitoAtencaoStore.reset();
		history.push(`/sujeito-atencao`);
	};

	const handleClickPaciente = (id) => {
		sujeitoAtencaoStore.reset();
		sujeitoAtencaoStore.changeId(id);
		history.push(`/sujeito-atencao/edit/${id}`);
	};

	const handleClickFilterButton = () => {
		setOpenFilterModal(true);
	};

	const handleFilterChange = (value, field) => {
		const { sujeitoAtencaoFilter } = sujeitoAtencaoStore;
		sujeitoAtencaoStore.sujeitoAtencaoFilter = {
			...sujeitoAtencaoFilter,
			[field]: value,
			pageNumber: 0,
		}
	};

	const handleSort = async (value) => {
		const sortDir = sujeitoAtencaoFilter.sortDir === 'ASC' && sujeitoAtencaoFilter.sortField === value ? 'DESC' : 'ASC';
		
		const updatedFilter = {
			...sujeitoAtencaoFilter,
			pageNumber: 0,
			sortField: value,
			sortDir
		}

		sujeitoAtencaoStore.sujeitoAtencaoFilter = updatedFilter;

		loadSujeitosAtencao();
	}

	const handleClickSwitch = async (sujeitoAtencao) => {
		if(!canUpdateStatus) return;
		try {
			const { id } = sujeitoAtencao;
			const queryName = sujeitoAtencao?.ativo ? 'desativarSujeitoAtencao' : 'ativarSujeitoAtencao';
			const response  = sujeitoAtencao?.ativo ? await desativarSujeitoAtencao(id) : await ativarSujeitoAtencao(id);

			if (!response?.data?.data[queryName]?.id) {
				throw Error('Falha ao atualizar o status do paciente');
			}

			refreshUpdatedPacienteStatus(sujeitoAtencao.id, !sujeitoAtencao.ativo);
		} catch (error) {
			sujeitoAtencaoStore.openNotification(error.message, 'error');
		}
	}

	const refreshUpdatedPacienteStatus = (sujeitoId, newStatus) => {
		const sujeitoIndex = elements.findIndex(sujeitoAtencao => sujeitoAtencao.id === sujeitoId);
		let updatedElements = [...elements];
		updatedElements[sujeitoIndex] = {
			...updatedElements[sujeitoIndex],
			ativo: newStatus
		};
		setElements(updatedElements);
	} 

	const handlePrintReport = async () => {
		const base64 = await consultaAgendamentoCSV();
		if (base64 !== 'error') {
			const blob = base64Convert(base64);
			const url = window.URL.createObjectURL(blob);
			var link = document.createElement('a');
			link.setAttribute('href', url);
			link.setAttribute("download", "pacientes.csv");
			document.body.appendChild(link);
			link.click();
		}
	};

	const consultaAgendamentoCSV = async () => {
		const { sujeitoAtencaoFilter } = sujeitoAtencaoStore;
		const { profissionalSaudeId, convenioId, ativo, municipioId, search: searchText } = sujeitoAtencaoFilter || {};

		if (!loadingCsv) {
			setLoadingCsv(true);
			const search = {
				profissionalSaudeId: Number(profissionalSaudeId?.id) || null,
				convenioId: Number(convenioId?.id) || null,
				ativo: ativo.value,
				municipioId: Number(municipioId?.value) || null,
				search: searchText || '',
			};

			const pageableDTO = {
				sortDir: sujeitoAtencaoFilter.sortDir,
				sortField: sujeitoAtencaoFilter.sortField
			}

			const response = await sujeitoAtencaoCsv({ search, pageableDTO });

			setLoadingCsv(false);
			return response;
		}
	}

	const columns = getDynamicColumns(allCamposSelected, handleClickSwitch, togglePersonalizarColunas)
	useEffect(() => {
		(async () => {
			const containsRole = await checkUserRole('ROLE_SUJEITO_ATENCAO_UPDATE');
			setCanUpdateStatus(containsRole);
		})()
	}, []);


	const { isLoadingTable, sujeitoAtencaoFilter, notification } = sujeitoAtencaoStore; 

	return (
		<div className={classes.root}>
			<PageTitle title="Pacientes"/>
			<PacientesHeader 
				handleClickActionButton={handleClickNewPaciente}
				quantidadeAtivo={elements?.length || 0}
				quantidadeTotal={sujeitoAtencaoQuantidadeTotal}
			/>
			<div className={classes.contentContainer}>
				<Filter 
					onClickFilterButton={handleClickFilterButton}
					onChange={handleFilterChange}
					filter={sujeitoAtencaoFilter}
					handleApplyFilter={loadSujeitosAtencao}
					isLoading={isLoadingTable}
				/>
				<PacienteContent
					columns={columns}
					elements={elements}
					onClickRow={handleClickPaciente}
					isLoading={isLoadingTable}
					loadSujeitosAtencao={loadSujeitosAtencao}
					hasMore={sujeitoAtencaoStore.hasMoreToLoad}
					filter={sujeitoAtencaoFilter}
					handleSort={handleSort}
				/>
				<FilterModal
					open={openFilterModal}
					handleClose={() => setOpenFilterModal(false)}
					handleFilterChange={handleFilterChange}
					handleApplyFilter={loadSujeitosAtencao}
					filter={sujeitoAtencaoFilter}
					unidadeLogada={unidadeLogada}
					loading={isLoadingTable}
					profissionaisSaude={profissionaisSaude}
				/>
				{profissionalLogado?.tipo !== 4 &&
					<div className={classes.contentButton}>
						<Button
							shape='circle'
							onClick={handleClickNewPaciente}
						>
							<Add/>
						</Button>
						<Button
							shape='circle'
							bgColor='#F9BE73'
							shadow
							onClick={handlePrintReport}
							disabled={loadingCsv}
						>
							<ArrowDownloadIcon />
						</Button>
					</div>
        }
			</div>
			<Notification
				close={sujeitoAtencaoStore.closeNotification}
				reset={sujeitoAtencaoStore.resetNotification}
				isOpen={notification.isOpen}
				variant={notification.variant}
				message={notification.message}
			/>
			<PersonalizarColunas 
				open={openPersonalizarColunas} 
				onClose={togglePersonalizarColunas} 
				fieldsAlreadyUpdated={getCamposSelected}
				openNotification={sujeitoAtencaoStore.openNotification}
			/>
			<Footer />
		</div>
	)
});

const SujeitosAtencaoStyles = withStyles(styles)(SujeitosAtencao);
const stores = ["sujeitoAtencaoStore", "usuarioStore", "profissionalSaudeStore", "unidadeStore", "googleAnalyticsStore", 'controleSessaoStore'];
export default inject(...stores)(SujeitosAtencaoStyles);
