import React from "react";
import debounce from "lodash.debounce";
import moment from "moment";
import 'moment/locale/pt-br';
import classnames from "classnames";
import {inject, observer} from "mobx-react";
import AddIcon from "@material-ui/icons/Add";
import {
    Remove as RemoveIcon,
} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import Table from "../../../components/Table/Table";
import {
    Grid,
    CircularProgress,
    Fab,
    Typography,
    Checkbox,
    Tooltip
} from "@material-ui/core";
import {BlobProvider} from "@react-pdf/renderer";

import styles from "../../../assets/jss/pages/extratoStyle";
import {applyCurrencyMask} from "../../../utils/CurrencyMask";
import string from "../../../utils/string";
import {
    TituloTipoRepeticao,
    TituloTipo
} from "../../../stores/Financeiro/Extrato.store";
import SpeedDials from "../../../components/SpeedDials";
import printPage from "../../../utils/printPage";
import RelatorioExtratoPdfDocument from "../../../template/pdf/financeiro/relatorioExtrato";
import {findUnidadeLogada} from "../../../services/UnidadeService";
import printIcon from "../../../assets/img/svg/print-icon.svg";
import Api from '../../../config/api'
import Profile from "../../../template/Header/Profile";
import InfoExpansiveButton from "../../../components/Button/InfoExpansiveButton";
import InputSearchFilter from "../../../components/Input/InputSearchFilter";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { DownloadIcon } from "../../../assets/img/svg";
import { base64Convert } from "../../../utils/base64ToCsv";
import { faturarLoteTituloParcela, relatorioTituloParcelaCsv } from "../../../services/FinanceiroService";
import Notification from "../../../components/Notification";
import LancamentoModal from "./LancamentoModal";
import FiltroDataModal from "./FiltroDataModal/FiltroDataModal";
import ModalConfirmacao from "../../../components/Modal/ModalConfirmacao/ModalConfirmacao";
import WarningIcon from "../../../components/Icon/Warning";
import { Button } from "../../../components/ui/Buttons";
import MoneyIcon from "../../../components/Icon/Money";
import {
	InputDateForm,
} from "../../../components/Modal/Input";
import ModalInfoBaixa from "./Modal/Info";
import ItemGrid from "../../../components/Modal/ItemGridCard";
import InfoIconFilled from "../../../components/Icon/InfoIconFilled";

const getColumns = ({ handleCheckAll, handleChange, titulosSelecionados, allCheckBoxChecked}) => {
    const columns = [
        {
            Header: (<Checkbox
            color='primary'
            checked={allCheckBoxChecked()}
            onChange={e => handleCheckAll(e)}
            />),
            isClick: true,
            getValue: row => {
                if(row.dataPagamento || row.excluido) return null;
                const checked = titulosSelecionados.includes(row.id);
                return (
                <Checkbox
                    color='primary'
                    checked={checked}
                    onChange={e => handleChange(e, row)}
                />
            )
            }
        },
        {
            Header: "Status",
            getValue: row => {
                let statusPagamento = "";

                if(row.dataPagamento) {
                    statusPagamento = "Pago"
                } else {
                    statusPagamento = moment(row.dataVencimento).isSameOrAfter(moment(), 'date') ? "Aberto" : "Atrasado"
                }

                if(row.excluido) {
                    statusPagamento = "Excluído"
                }

                return <div className={classnames("statusPagamento", statusPagamento)}> { statusPagamento } </div>;
            }
        },
        {
            Header: "Data Vencimento",
            field: "dataVencimento",
            getValue: row => {
                if (string.isEmpty(row.dataVencimento)) {
                    return "-";
                }
                return moment(row.dataVencimento).format("DD/MM/YYYY");
            }
        },
        {
            Header: "Data pagamento",
            getValue: row => {
                if (string.isEmpty(row.dataPagamento)) {
                    return "-";
                }
                return moment(row.dataPagamento).format("DD/MM/YYYY");
            }
        },
        {
            Header: "Valor",
            getValue: row => {
                if (typeof row.valor !== "number") {
                    return "-";
                }

                const valorComDesconto = string.calculaDesconto(row.valor, row.desconto, row.tipoDesconto)
                if(row?.agendamento?.voucherPrever?.id) {
                    return (
                        <div 
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: '5px', 
                            backgroundColor: '#219A97', 
                            borderRadius: '8px', 
                            padding: '8px', 
                            color: "#fff"
                        }}>
                            <MoneyIcon  color='#fff' />
                            Voucher
                        </div>
                    )
                }
                return applyCurrencyMask(row.tipoRepeticao === 'NAO_REPETIR' ? row.valor : valorComDesconto)
            }
        },
        {
            Header: "Descrição",
            field: "nome",
            getValue: ({nome, numeroParcela, tipoRepeticao, totalParcelas}) => {
                if (tipoRepeticao === TituloTipoRepeticao.NAO_REPETIR) {
                    return nome;
                }
                return `${
                    numeroParcela === 0 ? "E" : numeroParcela
                }/${totalParcelas} ${nome}`;
            }
        },
        {
            Header: "Profissional de saúde",
            field: "profissionalSaude.nome",
            getValue: (row) => {
                return row.profissionalSaude?.nome
                    ? row.profissionalSaude.nome
                    : "-";
            },
        },
        {
            Header: "Paciente",
            field: "sujeitoAtencao.nome",
            getValue: (row) => {
                return row.sujeitoAtencao?.nome ? row.sujeitoAtencao.nome : "-";
            },
        },
        {
            Header: "Convênio",
            field: "convenio.descricao",
            getValue: (row) => {
                return row.convenio?.descricao || '-'
            }
        },
        {
            Header: "Categoria",
            field: "categoriaFinanceira",
            getValue: row => {
                if (row.categoriaFinanceira && row.categoriaFinanceira.nome) {
                    return row.categoriaFinanceira.nome;
                }
                return "-";
            }
        },
        {
            Header: "Conta bancária",
            field: "contaBancaria",
            getValue: row => {
                return row.contaBancaria?.nome || '-'
            }
        },
        {
            Header: "Saldo",
            getValue: row => {
                if (typeof row.saldo !== "number") {
                    return "-";
                }
                return applyCurrencyMask(row.saldo);
            }
        },
        {
            Header: "Forma de pagamento",
            field: "formaPagamento.nome",
            getValue: (row) => {
                return row.formaPagamento?.nome || '-'
            },
        },
        {
            Header: "Cód. Transação",
            field: "codigoTransicao",
            getValue: row => row.codigoTransicao,
        },
    ];
    return columns;
};

const defaultFilterLancamento = {
    profissionalSaude: null,
    sujeitoAtencao: null,
    meioPagamento: null,
    centroCusto: null,
    categoria: null,
    convenio: null,
    contaBancaria: null,
    tipo: null,
    procedimento: null,
    tipoConsulta: null,
};

const statusDefaultList = [
    {
        value: 'PAGO',
        nome: 'Pago',
        checked: false
    },
    {
        value: 'ATRASADO',
        nome: 'Atrasado',
        checked: false
    },
    {
        value: 'ABERTO',
        nome: 'Aberto',
        checked: false
    },
    {
        value: 'EXCLUIDO',
        nome: 'Excluído',
        checked: false
    },
];

@inject("extratoStore", "profissionalSaudeStore", "usuarioStore", "configuracaoImpressaoStore", "googleAnalyticsStore", "categoriaFinanceiraStore", "centroDeCustoStore", "sujeitoAtencaoStore")
@observer
class Lancamento extends React.Component {
    constructor(props) {
        super(props);

        this.debounceConsulta = debounce(this.debounceConsulta, 500);

        this.state = {
            filterDateInicioSelected: moment().startOf("month").format("YYYY-MM-DD"),
            filterDateFinalSelected: moment().endOf("month").format("YYYY-MM-DD"),
            profissionalSaude: null,
            unidade: null,
            printing: false,
            centroCustoList: [],
            categoriaFinanceiraList: [],
            sujeitoAtencaoList: [],
            formasPagamento: [],
            formaPagamentoSelecionadoList: [],
            ordenarTabela: {
                sortField: "dataVencimento",
                sortDir: "ASC",
            },
            contaBancaria: null,
            isMesAtual: true,
            filterLancamento: defaultFilterLancamento,
            quantidadeChecked: 0,
            quantidadeCheckedStatus: 0,
            statusList: statusDefaultList,
            loadingCsv: false,
            titulosSelecionados: [],
            modalBaixaLancamento: {
                info: false,
                confirmar: false,
            },
            loadingFaturarLote: false,
            dataPagamentoBaixa: null,
            notification: {
                isOpen: false,
                message: ''
            },
        }

        this.props.extratoStore.findAllContaBancaria({search: this.state.searchContaBancaria});
    }

    async componentDidMount() {
        const {extratoStore} = this.props;
        extratoStore.clearFilters();
        await extratoStore.carregaDadosFinanceiro();
        this.verificaMesAtual();
        this.carregaFiltrosSearch();

        extratoStore.findAll();
        const paginaVisualizada = {
            page: window.location.hash,
            title: 'Lancamento'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);
    }

    debounceConsulta() {
        const {extratoStore} = this.props;

        extratoStore.findAll();
    }

    async carregaSujeitoAtencao(query, callback) {
        let sujeitoList = await this.consultaSujeitosDeAtencao(query);
        sujeitoList = sujeitoList?.map(sujeito => ({
            name: sujeito.nome,
            label: sujeito.nome,
            value: sujeito.id
        }));

        this.setState({sujeitoAtencaoList: sujeitoList});
        callback(sujeitoList);
    }

    carregaFiltrosSearch = async() => {
        this.carregaFormasPagamento();
    };

    carregaFormasPagamento = () => {
        const { extratoStore } = this.props;

        const formasPagamento = extratoStore.formasPagamento.map(item => {
            return {
              ...item,
              checked: false
            }
        });

        this.setState({ formasPagamento });
    };

    async consultaSujeitosDeAtencao(search = null) {
        const response = await Api.post('',
            {
                query: `
                    query ($page: SearchSujeitoAtencaoDTOInput){
                        findAllSujeitoAtencao(searchDTO: $page){
                            totalElements
                            numberOfElements
                            content {
                                id
                                nome
                            }
                        }
                    }`,
                variables: {
                    page: {
                        search: search,
                        pageSize: 20,
                        pageNumber: 0,
                        sortField: 'nome',
                        sortDir: 'ASC'
                    }
                }
            }
        );
        const {findAllSujeitoAtencao} = response?.data?.data;

        return findAllSujeitoAtencao.content;
    };

    handleSearchChange = e => {
        const {extratoStore} = this.props;
        const search = e.target.value;
        extratoStore.clearPesquisar();
        extratoStore.searchDTO.search = search;
    };

    onClickPesquisar = () => {
        const { extratoStore } = this.props;
        extratoStore.findAll();
    };

    onKeypressSearch = (e) => {
        const { extratoStore } = this.props;

        if (e.key === 'Enter' && extratoStore.searchDTO.search.length >= 3) {
            extratoStore.findAll();
        }
    };

    handleClickClearPesquisar = e => {
        const { extratoStore } = this.props;

        extratoStore.clearPesquisar();
        extratoStore.searchDTO.search = "";
        extratoStore.findAll();
    };

    handleApplyFilter = filterOptions => {
        this.setState({
            filterDateInicioSelected: moment(filterOptions.dataInicial),
            filterDateFinalSelected: moment(filterOptions.dataFinal),
        }, () => {
            const { extratoStore } = this.props;
            const { filterDateInicioSelected, filterDateFinalSelected } = this.state;
            
            if (filterDateInicioSelected instanceof moment && filterDateInicioSelected instanceof moment) {
                extratoStore.clearPesquisar();
                extratoStore.searchDTO.dataInicial = filterDateInicioSelected.format('YYYY-MM-DD');
                extratoStore.searchDTO.dataFinal = filterDateFinalSelected.format('YYYY-MM-DD');
                extratoStore.filterBy = filterOptions.typeFilter;
                extratoStore.findAll();
            }
            this.verificaMesAtual()
        });
    };

    handleSujeitoAtencaoChange = e => {
       const {extratoStore} = this.props;

       extratoStore.clearPesquisar();
       this.setState({
           ...this.state,
           sujeitoAtencao: e
       });
       extratoStore.searchDTO.sujeitoAtencaoId = e?.value;
       this.debounceConsulta();
    };

    handleFormaPagamentoChange = e => {
       const {extratoStore} = this.props;

       this.setState({ 
            ...this.state,
            formaPagamento: e,
           formaPagamentoSelecionadoList: e 
        });
        
        let listaId = e.map(item => ({
            id: item.id
        }))
        extratoStore.searchDTO.formasPagamentoId = listaId;
        extratoStore.findAll();

    };

    loadMore = () => {
        const {extratoStore} = this.props;

        extratoStore.findAll();
    };

    handleItemClick = id => {
        const {extratoStore} = this.props;

        extratoStore.edit(id);
    };

    handleClickOrdenar = async (value) => {
        const {extratoStore} = this.props;
        const { ordenarTabela } = this.state;
        
        const sortDir = ordenarTabela.sortField !== value ? "ASC" : (ordenarTabela.sortDir === "ASC" ? "DESC" : "ASC")

        extratoStore.clearPesquisar();

        this.setState({            
            ordenarTabela: { 
              sortDir: sortDir,
              sortField: value,
            },
        });
        extratoStore.findAll({}, {sortDir: sortDir, sortField: value});

    }

    handleClickAction = action => {
        const {extratoStore} = this.props;
        
        extratoStore.isAgendamento = false;
        extratoStore.openNew(action.tipo);
    };

    handlePrint = async () => {
      const unidadeLogada = await findUnidadeLogada();

      this.setState({
          unidade: unidadeLogada,
          printing: true
      });
    };

    getCsv = async() =>{
        const base64 = await this.tituloParcelaCsv()

        if(base64){
            const blob = base64Convert(base64)
            const url  = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "relatorioTituloParcela.csv");
            document.body.appendChild(link);
            link.click();
        }
    };

    tituloParcelaCsv = async () => {
        const { extratoStore } = this.props;

        try {
            this.setState({loadingCsv: true});

            const variables = extratoStore.searchDTO;
            delete variables.pageableDTO;

            const response = await relatorioTituloParcelaCsv({ nomeQuery: extratoStore.relatorioCsv, variables });

            return response;
        } catch (error) {
            const notification = {
                isOpen: true,
                message: 'Erro ao baixar o relatório.'
            }
            this.showAlertMessage(notification);

        } finally {
            this.setState({loadingCsv: false})
        }
    }

    showAlertMessage = (notification) => {
        this.setState({ notification });
        
        const timeoutId = setTimeout(() => {
          this.closeAlertMessage();
          clearTimeout(timeoutId);
        }, 3000);
    };
    
    closeAlertMessage = () => {
        const notification = {
        isOpen: false,
        message: ''
        };
        this.setState({ notification });
    };


    handleChangeSearchFilter = (e, field, searchDTOId) => {
        const {extratoStore} = this.props;
        const {filterLancamento} = this.state;
   
        this.setState({
            filterLancamento: {
                ...filterLancamento,
                [field]: e
        }});
        extratoStore.searchDTO[searchDTOId] = field === 'sujeitoAtencao' ? e?.value : e?.id;
    };

    handleSujeitoAtencaoLoadOptions = async (search, loadedOptions, { page }) => {
        return this.handleLoadMoreOptions({
            search,
            loadedOptions,
            data: { page },
            method: "consultaSujeitosDeAtencao"
        });
    };

    handleLoadMoreOptions = async ({ search, data, method }) => {
        const { extratoStore } = this.props;
        let searchDTO = {};
        
        if (data.searchDTO) {
          searchDTO = {
            ...data.searchDTO
          };
        }
        const response = await extratoStore[method]({
          pageNumber: data.page,
          search,
          ...searchDTO
        });
        return {
          options: response.content,
          hasMore: !response.last,
          additional: {
            page: data.page + 1
          }
        };
    };

    renderPrintPdf = () => {
        const {configuracaoImpressaoStore, extratoStore} = this.props;

        return (
            <div>
                <BlobProvider
                    document={(
                        <RelatorioExtratoPdfDocument
                            config={configuracaoImpressaoStore.configuracaoImpressao}
                            extratos={extratoStore.tituloList}
                            totais={extratoStore.totais}
                            unidade={this.state.unidade}
                        />
                    )}>
                    {({url, loading}) => {
                        if (!loading) {
                            printPage(url);
                            this.setState({printing: false})
                        }
                        return null;
                    }}
                </BlobProvider>
            </div>
        );
    };

    excluiFormaPagamentoSelecionado = (index) => {
        const { extratoStore } = this.props;
        const { formaPagamentoSelecionadoList } = this.state;
        const lista = formaPagamentoSelecionadoList

        lista.splice(index, 1);
        this.setState({ formaPagamentoSelecionadoList: lista }, () => {
            lista.forEach(item => {
                return(
                    {
                        id: item.id
                    }
                )
            })
        });

        extratoStore.searchDTO.formasPagamentoId = lista;
        extratoStore.findAll();
    };

    toggleInfoSaldo = () => {
        const { openInfoSaldo } = this.state;
        this.setState({ openInfoSaldo: !openInfoSaldo });
    };

    handleMesAtual = () => {
        const { extratoStore } = this.props;
        const primeiroDiaMesAtual = moment().startOf("month").format("YYYY-MM-DD");
        const ultimoDiaMesAtual = moment().endOf("month").format("YYYY-MM-DD");

        extratoStore.searchDTO.dataInicial=primeiroDiaMesAtual;
        extratoStore.searchDTO.dataFinal= ultimoDiaMesAtual;

        this.setState({
            filterDateInicioSelected: primeiroDiaMesAtual,
            filterDateFinalSelected: ultimoDiaMesAtual,
            isMesAtual: true,
        }, () => {
            extratoStore.findAll();
        });
    };

    verificaMesAtual = () => {
        const { filterDateInicioSelected, filterDateFinalSelected } = this.state;

        const dataInicial = moment(filterDateInicioSelected).format("YYYY-MM-DD");
        const dataFinal = moment(filterDateFinalSelected).format("YYYY-MM-DD");

        if( dataInicial === moment().startOf("month").format("YYYY-MM-DD") && dataFinal === moment().endOf("month").format("YYYY-MM-DD")){
            this.setState({ isMesAtual: true });
            return;
        };

        this.setState({ isMesAtual: false });
    };

    handleClicksearchLancamento = async () => {
        const { extratoStore } = this.props;
        extratoStore.findAll();

    };

    redefinirFiltros = () => {
        const { extratoStore } = this.props;

        this.setState({
            filterLancamento: defaultFilterLancamento,
            quantidadeChecked: 0,
            quantidadeCheckedStatus: 0,
            statusList: statusDefaultList
        }, () => {
            extratoStore.resetSearchFiltros();
            this.carregaFormasPagamento();
        });
    }

    changeCheckbox = (formaPagamento) => {
        const { formasPagamento } = this.state;
        const formasPagamentoChecked = formasPagamento.map(item => {
            return  formaPagamento.id === item.id ?
            {
                ...item,
                checked: !item.checked
            }
            : item
        });

        this.setState({ formasPagamento: formasPagamentoChecked }, () => {
            this.verificaQuantidadeFormaPagamento();
        });
    };

    changeCheckboxStatus = (status) => {
        const { statusList } = this.state;

        const statusChecked = statusList.map(item => {
            return  status.value === item.value ?
            {
                ...item,
                checked: !item.checked
            }
            : item
        });

        this.setState({ statusList: statusChecked }, () => {
            this.verificaQuantidadeStatus();
        });
    };

    verificaQuantidadeStatus = () => {
        const { extratoStore } = this.props;
        const { statusList } = this.state;

        const listaChecked = statusList.filter(item => item.checked);
        extratoStore.searchDTO.status = listaChecked.map(item => item.value);
        this.setState({ quantidadeCheckedStatus: listaChecked.length });
    };

    verificaQuantidadeFormaPagamento = () => {
        const { extratoStore } = this.props;
        const { formasPagamento } = this.state;

        const listaChecked = formasPagamento.filter(item => item.checked);
        
        extratoStore.searchDTO.formasPagamentoId = listaChecked.map(item => {
            return({
                id: item.id
            })
        })
        this.setState({ quantidadeChecked: listaChecked.length });
    }

    handleCheckAllTitulos = (e) => {
        const { extratoStore } = this.props;
        const { tituloList } = extratoStore;
        const { checked } = e.target;

        if(checked){
            this.setState({ titulosSelecionados: tituloList.filter(item => !item?.dataPagamento).map(item => { return item.id })});
        }else{
            this.setState({ titulosSelecionados: [] });
        }
    }

    handleCheckTitulo = (e, id) => {
        const { checked } = e.target;

        if(checked){
            this.setState({ titulosSelecionados: [...this.state.titulosSelecionados, id] });
        }else{
            this.setState({ titulosSelecionados: this.state.titulosSelecionados.filter(item => item !== id) });
        }
    }

    allCheckBoxChecked = () => {
        const { extratoStore } = this.props;
        const { tituloList } = extratoStore;
        const { titulosSelecionados } = this.state;
        const titulosNaoPagos = tituloList.filter(item => !item?.dataPagamento).map(item => { return item.id });
        return titulosNaoPagos.length === titulosSelecionados.length;
    }

    toggleModalInfosBaixa = (field, value) => {
        this.setState({ modalBaixaLancamento: { ...this.state.modalBaixaLancamento, [field]: value } });
    }

    handleChangeDataPagamento = (data) => {
        if(moment(data).isAfter(moment(), 'date')){ 
            this.showAlertMessage({ 
                isOpen: true,
                message: 'Data de pagamento maior que a data atual.'
            });
            return;
        }
        this.setState({ dataPagamentoBaixa: data });
    }

    handleFaturarLotes = async () => {
        const { extratoStore } = this.props;
        const { titulosSelecionados, dataPagamentoBaixa } = this.state;
        this.setState({ loadingFaturarLote: true });
        try {
            await faturarLoteTituloParcela({
                tituloIds: titulosSelecionados,
                dataPagamento: moment(dataPagamentoBaixa).format('YYYY-MM-DD')
            });
            await extratoStore.findAll();
            this.setState({ titulosSelecionados: [] });
        } catch (error) {
            this.showAlertMessage({
                isOpen: true,
                message: 'Erro ao realizar a baixa.'
            });
        } finally {
            this.toggleModalInfosBaixa('confirmar', false);
            this.setState({ loadingFaturarLote: false });
        }
    }

    render() {
        const {filterDateInicioSelected, filterDateFinalSelected, openInfoSaldo, isMesAtual, modalBaixaLancamento} = this.state;
        const {classes, extratoStore} = this.props;
        const {
            loading,
            tituloList,
            open,
            totais,
            modalConfirmarExcluir,
        } = extratoStore;

        const actions = [
            {
                icon: <RemoveIcon/>,
                name: "Despesa",
                tipo: TituloTipo.DESPESA,
                buttonClasses: classes.buttonAddDespesa
            },
            {
                icon: <AddIcon/>,
                name: "Receita",
                tipo: TituloTipo.RECEITA,
                buttonClasses: classes.buttonAddReceita
            }
        ];

        return (
            <div className={classes.content}>
                <PageTitle title="Financeiro - Lançamentos" />
                <header className={classes.header} >
                    <Grid item className={classes.containerHeader}>
                        <div className={classes.contentSearch}>
                            <div className={classes.titulo}> Lançamentos </div>
                            <InputSearchFilter
                                onChange={this.handleSearchChange} 
                                search={this.handleClicksearchLancamento}
                                value={extratoStore.searchDTO.search}
                                placeholder="Pesquisar" 
                                handleChangeInput={this.handleChangeSearchFilter}
                                handleSujeitoAtencaoLoadOptions={this.handleSujeitoAtencaoLoadOptions}
                                handleFormaPagamentoChange={this.handleFormaPagamentoChange}
                                searchLancamentoFinanceiro={this.handleClicksearchLancamento}
                                filterLancamento={this.state.filterLancamento}
                                redefinirFiltros={this.redefinirFiltros}
                                clearSearch={this.handleClickClearPesquisar}
                                onKeyPress={this.onKeypressSearch}
                                quantidadeChecked={this.state.quantidadeChecked}
                                formaPagamentoList={this.state.formasPagamento}
                                changeCheckbox={this.changeCheckbox}
                                changeCheckboxStatus={this.changeCheckboxStatus}
                                quantidadeCheckedStatus={this.state.quantidadeCheckedStatus}
                                statusList={this.state.statusList}
                                screen={'LancamentoFinanceiro'}
                                classes={{ 
                                    root: classes.inputSearch,
                                    popper: classes.inputSearch,
                                    paper: classes.paperInputSearch
                                }}
                            />
                        </div>
                        <div className={classes.filterDateContainer}>
                            <span className={classes.tituloFiltros}> Filtrar por data: </span>
                            <FiltroDataModal 
                                dataInicio={filterDateInicioSelected}
                                dataFinal={filterDateFinalSelected}
                                onApply={this.handleApplyFilter}
                                isMesAtual={isMesAtual}
                            />
                        </div>
                        <Grid item className={classes.wrapperFilterMonth}>
                            <Button
                            kind='transparent'
                            shape='round'
                            onClick={this.handleMesAtual}
                            disabled={isMesAtual}
                            >
                                Mês atual
                            </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.wrapperInfosLancamentos}>
                            <span className={classes.infosLancamentos}>
                                Receitas <strong style={{color:"#219A97"}}>{totais.totalReceitaFormatted}</strong>
                            </span>
                            <span className={classes.infosLancamentos}>
                                Despesas <strong style={{color:"#FB7676"}}>{totais.totalDespesaFormatted}</strong>
                            </span>
                            <span className={classes.infosLancamentos}>
                                Total
                                <strong style={{color:"#000"}}>
                                {totais.totalFormatted}
                                </strong>
                            </span>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.wrapperProfile}>
                        <Tooltip
                            title={'Dar baixa'}
                            placement="top"
                            classes={{ tooltip: classes.tooltip }} 
                        >
                            <Button
                                kind='primary' 
                                shape='circle'
                                onClick={() => this.toggleModalInfosBaixa('info', true)} disabled={this.state.titulosSelecionados.length === 0}
                                >
                                <MoneyIcon color='#fff'/>
                            </Button>
                        </Tooltip>
                        <Profile />
                    </Grid>
                </header>
                <div className={classes.tableContainer}>
                    {loading &&
                        <Grid
                        container
                        justify="center"
                        alignItems="center"
                        style={{ height: "100%" }}
                        >
                            <CircularProgress size={30} />
                        </Grid>
                    }

                    {!loading && tituloList.length === 0 && (
                        <div className={classes.notFoundContainer}>
                            <h3>Nenhum item encontrado</h3>
                        </div>
                    )}

                    {!loading && tituloList.length > 0 && (
                        <div className={classes.tableWrapper}>
                            <Table
                                className={classes.table}
                                dados={tituloList}
                                columns={getColumns({
                                    handleCheckAll: (e) => this.handleCheckAllTitulos(e),
                                    handleChange: (e, data) => this.handleCheckTitulo(e, data.id),
                                    titulosSelecionados: this.state.titulosSelecionados,
                                    allCheckBoxChecked: this.allCheckBoxChecked
                                })}
                                clickable={true}
                                handleClick={this.handleItemClick}
                                comOrdenacao
                                ordenarTabela={this.state.ordenarTabela}
                                handleClickOrdenar={this.handleClickOrdenar}
                            />
                        </div>
                    )}
                    {this.state.printing && this.renderPrintPdf()}
                    <div className={classes.floatButtonsContainer}>
                        <SpeedDials
                            actions={actions}
                            onClickAction={this.handleClickAction}
                        />
                        <Fab className={classes.buttonImprimir} onClick={this.handlePrint} disabled={this.state.printing} size="small">
                            <img src={printIcon} alt={'icon'}/>
                        </Fab>
                        <Fab
                            className={classes.buttonImprimir}
                            onClick={() => this.getCsv()} 
                            disabled={this.state.loadingCsv}
                        >
                            <img src={DownloadIcon} alt="Download" />
                        </Fab>
                    </div>

                    <InfoExpansiveButton
                        classes={{barraInfoFechado: classes.infoButton,
                            childrenContent: classes.contentTotalDespesasEReceitas
                        }}
                        openInfo={openInfoSaldo}
                        handleOpenInfo={this.toggleInfoSaldo}
                    >
                            <Typography className={classes.totalReceitaLabel}>
                                Total Receita: <span>{totais.totalReceitaFormatted}</span>
                            </Typography>
                            <Typography className={classes.totalDespesaLabel}>
                                Total Despesa: <span>{totais.totalDespesaFormatted}</span>
                            </Typography>
                            <Typography className={classes.totalLabel}>
                                Total:
                                <span
                                className={totais.total > 0 ? "positivo" : "negativo"}
                                >
                                {totais.totalFormatted}
                                </span>
                            </Typography>
                    </InfoExpansiveButton>
                </div>
                {open &&  <LancamentoModal open={open} />}
                <ModalConfirmacao
                    open={modalConfirmarExcluir.open}
                    icon={<WarningIcon/>}
                    color={"red"}
                    title={"Essa ação não poderá ser desfeita, você deseja continuar?"}
                    buttons={{...modalConfirmarExcluir.buttons,
                        disabledSecondButton: extratoStore.loadingButtonActionModal
                    }}
                >
                </ModalConfirmacao>
                {modalBaixaLancamento.info && (
                    <ModalInfoBaixa
                        open={modalBaixaLancamento.info}
                        onClose={() => this.toggleModalInfosBaixa('info', false)}
                        title={"Dar baixa aos lançamentos"}
                    >
                    <div className={classes.wrapperInfosModal}>
                        <ItemGrid size={12} label={"Defina a data de pagamento"}>
                            <InputDateForm
                                name={"dataPagamento"}
                                value={this.state.dataPagamentoBaixa}
                                placeholder={"00/00/0000"}
                                className={classes.inputContainer}
                                iconposition="end"
                                onChange={(e) => this.handleChangeDataPagamento(e)}
                            />
                        </ItemGrid>
                        <Button
                            kind='primary'
                            fullWidth
                            disabled={!this.state.dataPagamentoBaixa}
                            onClick={async () => {
                                await this.toggleModalInfosBaixa('info', false)
                                this.toggleModalInfosBaixa('confirmar', true)
                            }}
                        >
                            Dar baixa ({this.state.titulosSelecionados.length})
                        </Button>
                    </div>
                    </ModalInfoBaixa>
                )}
                {modalBaixaLancamento.confirmar && (
                    <ModalInfoBaixa
                        open={modalBaixaLancamento.confirmar}
                        onClose={() => this.setState({ modalBaixaLancamento: { ...this.state.modalBaixaLancamento, confirmar: false } })}
                        icon={<InfoIconFilled color='white' size={24} />}
                    >
                        <div className={classes.wrapperInfosModal}>
                            <h5>Deseja dar baixa em {this.state.titulosSelecionados.length} lançamentos. Na data de pagamento {moment(this.state.dataPagamentoBaixa).format('DD/MM/YYYY')}?</h5>
                            <div className={classes.wrapperButtonsModal}>
                                <Button
                                    kind='transparent'
                                    fullWidth
                                    onClick={() => {
                                        this.toggleModalInfosBaixa('confirmar', false)
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    kind='primary'
                                    fullWidth
                                    isLoading={this.state.loadingFaturarLote}
                                    onClick={this.handleFaturarLotes}
                                >
                                    Confirmar
                                </Button>
                            </div>
                        </div>
                    </ModalInfoBaixa>
                )}
                <Notification
                    close={this.closeAlertMessage}
                    reset={this.closeAlertMessage}
                    isOpen={this.state.notification.isOpen}
                    variant={'error'}
                    message={this.state.notification.message}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Lancamento);
