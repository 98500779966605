import React from "react";
import InputDateForm from "../../../Input/InputDateForm";
import InputMaskCurrency from "../../../Input/InputMaskCurrency";
import PencilIcon from "../../../Icon/Pencil";
import TrashIcon from "../../../Icon/Trash";

export const getColumnsVigenciaProcedimento = ({ functions }) => {
  const { handleClickEditar, onChange, handleClickExcluir } = functions;

  const columns = [
    {
      Header: 'Inicio Vigencia',
      align: 'start',
      getValue: (data, index) => {
        return (
          <InputDateForm
            iconposition="end"
            invalidDateMessage=""
            disabled={data.disabled}
            value={data.dataInicioVigencia || ""}
            onChange={(e) => onChange(e, "dataInicioVigencia", index)}
          />)
      },
    },
    {
      Header: 'Fim Vigencia',
      align: 'start',
      getValue: (data, index) => {
        return (
          <InputDateForm
            iconposition="end"
            invalidDateMessage=""
            disabled={data.disabled}
            value={data.dataFimVigencia || ""}
            onChange={(e) => onChange(e, "dataFimVigencia", index)}
          />
        )
      },
    },
    {
      Header: 'Valor',
      align: 'start',
      getValue: (data, index) => {
        return (
          <div>
            <InputMaskCurrency
              placeholder='R$'
              disabled={data.disabled}
              style={{
                background: "#F2F2F2",
                borderRadius: "8px",
                padding: "5px",
                width: "100px",
                border: "0.5px solid rgba(220, 220, 220, 0.2)",
                textAlign: "center",
              }}
              value={data.valor || ""}
              onChange={(e) => onChange(e.target.value, "valor", index)}
            />
          </div>)
      },
    },
    {
      Header: 'Ações',
      align: 'start',
      getValue: (data, index) => {
        return (<div
          style={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            justifyContent: 'space-between',
          }}
        >
          <div>
            {data.disabled &&
              <PencilIcon
                size='20'
                onClick={() => handleClickEditar(data, index)}

              />
            }
          </div>
          <div>
            <TrashIcon
              size='20'
              onClick={() => handleClickExcluir(index, data)}
            />
          </div>
        </div>)
      },
    },
  ]
  return columns;
}
