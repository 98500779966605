import React from "react";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core/es";
import ItemGrid from "../../../../components/Modal/ItemGridCard";
import { ElementsList } from "../../constants/perfilViziConstants";
import CartaoCreditoWarning from "../../components/CartaoCreditoWarning";
import EspecialidadesValores from "../../components/EspecialidadesValores";
import styles from "../../../../assets/jss/pages/profissionalSaudeStyle";
import { ReactSelectSearch } from "../../../../components/Select/SelectSearch";

const Atividade = observer(
  ({ classes, profissionalSaudeStore, perfilPublicoStore }) => {
    const { specializationList } = profissionalSaudeStore;

    const handleChangeEspecilidadeValor = (value, field, index) => {
      const prevValue =
        perfilPublicoStore.perfilPublicoProfissional.especialidades[index][
          field
        ];

      const especialidades = perfilPublicoStore.perfilPublicoProfissional.especialidades;
      perfilPublicoStore.changeFieldsPublicoProfissionalStore({
        especialidades: especialidades.map((item, indexItem) => {
          return index === indexItem ? {
            ...item,
            [field]: value
          } : item
        })
      });

      if ((!prevValue && value) || (!value && prevValue)) {
        perfilPublicoStore.checkPerfilProfissionalIsComplete();
      }
    };

    const handleRemoveEspecialidade = (index) => {
      const updatedArray = perfilPublicoStore.perfilPublicoProfissional.especialidades.filter(
        (item, idx) => idx !== index
      );

      perfilPublicoStore.changeFieldsPublicoProfissionalStore({ especialidades: updatedArray });
      perfilPublicoStore.checkPerfilProfissionalIsComplete();
    };

    return (
      <div>
        <ItemGrid label={"Especialidades "} bold>
          <ReactSelectSearch
            isMulti
            className={[classes.inputContainer, classes.withMarginBottom]}
            name={ElementsList.ESPECIALIDADES}
            placeholder="Selecione suas especializações"
            elements={specializationList}
            value={
              perfilPublicoStore.perfilPublicoProfissional[
                ElementsList.ESPECIALIDADES
              ]
            }
            optionLabel="especialidade"
            onChange={(value) =>
              perfilPublicoStore.onFieldChange(
                value,
                ElementsList.ESPECIALIDADES
              )
            }
            showIsMulti={false}
          />
          <EspecialidadesValores
            especialidadesItems={
              perfilPublicoStore.perfilPublicoProfissional[
                ElementsList.ESPECIALIDADES
              ]
            }
            perfilType="profissional"
            utilizaTelemedicina={
              profissionalSaudeStore.profissionalSaude.utilizaTelemedicina
            }
            atendePresencial={
              profissionalSaudeStore.profissionalSaude.atendePresencial
            }
            onChangeValue={handleChangeEspecilidadeValor}
            removeFromList={handleRemoveEspecialidade}
          />
          {perfilPublicoStore.perfilPublicoProfissional.utilizaTelemedicina &&
            perfilPublicoStore.perfilPublicoProfissional.especialidades.length >
              0 && <CartaoCreditoWarning tax={6} />}
        </ItemGrid>
      </div>
    );
  }
);

const AtividadeWithStyles = withStyles(styles)(Atividade);
export default inject(
  "profissionalSaudeStore",
  "perfilPublicoStore"
)(AtividadeWithStyles);
